import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index'

Vue.use(VueRouter)
const routes = [
  { path: '/login', name: 'login', component: () => import('@/views/login.vue'), meta: { notLogin: true } },
  { path: '/', redirect: '/default' },
  { path: '/default', name: 'default', component: Home },

  { path: '/org/class', name: 'org-class', component: Home, children: [{ path: '/org/class', name: 'org-class', component: () => import('@/views/org/class/index.vue') }] },
  { path: '/org/teacher', name: 'org-teacher', component: Home, children: [{ path: '/org/teacher', name: 'org-teacher', component: () => import('@/views/org/teacher/index.vue') }] },
  { path: '/org/teahonor', name: 'org-teahonor', component: Home, children: [{ path: '/org/teahonor', name: 'org-teahonor', component: () => import('@/views/org/teahonor/tj.vue') }] },

  { path: '/fx/dict', name: 'fx-dict', component: Home, children: [{ path: '/fx/dict', name: 'fx-dict', component: () => import('@/views/fx/dict/index.vue') }] },
  { path: '/fx/remind', name: 'fx-remind', component: Home, children: [{ path: '/org/remind', name: 'fx-remind', component: () => import('@/views/fx/remind/index.vue') }] },
  { path: '/fix/cat', name: 'fix-cat', component: Home, children: [{ path: '/fix/cat', name: 'fix-cat', component: () => import('@/views/fix/cat/index.vue') }] },
  { path: '/fix/location', name: 'fix-location', component: Home, children: [{ path: '/fix/location', name: 'fix-location', component: () => import('@/views/fix/location/index.vue') }] },
  { path: '/fix/note', name: 'fix-note', component: Home, children: [{ path: '/fix/note', name: 'fix-note', component: () => import('@/views/fix/note/index.vue') }] },
  { path: '/fix/mender', name: 'fix-mender', component: Home, children: [{ path: '/fix/mender', name: 'fix-mender', component: () => import('@/views/fix/mender/index.vue') }] },

  { path: '/mat/cat', name: 'mat-cat', component: Home, children: [{ path: '/mat/cat', name: 'mat-cat', component: () => import('@/views/mat/cat/index.vue') }] },
  { path: '/mat/stock', name: 'mat-stock', component: Home, children: [{ path: '/mat/stock', name: 'mat-stock', component: () => import('@/views/mat/stock/index.vue') }] },
  { path: '/mat/bill/:tp', name: 'mat-bill', component: Home, children: [{ path: '/mat/bill/:tp', name: 'mat-bill', component: () => import('@/views/mat/bill/index.vue') }] },
  { path: '/mat/store', name: 'mat-store', component: Home, children: [{ path: '/mat/store', name: 'mat-store', component: () => import('@/views/mat/store/index.vue') }] },


  { path: '/mat/valid/act', name: 'mat-valid-act', component: Home, children: [{ path: '/mat/valid/act', name: 'mat-valid-act', component: () => import('@/views/mat/valid/act/index.vue') }] },
  { path: '/mat/valid/conf', name: 'mat-valid-conf', component: Home, children: [{ path: '/mat/valid/conf', name: 'mat-valid-conf', component: () => import('@/views/mat/valid/conf/index.vue') }] },

  { path: '/cap/info', name: 'cap-info', component: Home, children: [{ path: '/cap/info', name: 'cap-info', component: () => import('@/views/cap/info/index.vue') }] },

  { path: '/doc/archive', name: 'doc-archive', component: Home, children: [{ path: '/doc/archive', name: 'doc-archive', component: () => import('@/views/doc/archive/index.vue') }] },
  { path: '/doc/content', name: 'doc-content', component: Home, children: [{ path: '/doc/content', name: 'doc-content', component: () => import('@/views/doc/content/index.vue') }] },
  { path: '/doc/power', name: 'doc-power', component: Home, children: [{ path: '/doc/power', name: 'doc-power', component: () => import('@/views/doc/power/index.vue') }] },
  { path: '/doc/recycled', name: 'doc-recycled', component: Home, children: [{ path: '/doc/recycled', name: 'doc-recycled', component: () => import('@/views/doc/recycled/index.vue') }] },
  { path: '/doc/filetj', name: 'doc-filetj', component: Home, children: [{ path: '/doc/filetj', name: 'doc-filetj', component: () => import('@/views/doc/filetj/index.vue') }] },
  { path: '/doc/oplog', name: 'doc-oplog', component: Home, children: [{ path: '/doc/oplog', name: 'doc-oplog', component: () => import('@/views/doc/oplog/index.vue') }] },

  { path: '/safe/conf', name: 'safe-conf', component: Home, children: [{ path: '/safe/conf', name: 'safe-conf', component: () => import('@/views/safe/conf/index.vue') }] },
  { path: '/safe/rec', name: 'safe-rec', component: Home, children: [{ path: '/safe/rec', name: 'safe-rec', component: () => import('@/views/safe/rec/index.vue') }] },
  { path: '/safe/rep', name: 'safe-rep', component: Home, children: [{ path: '/safe/rep', name: 'safe-rep', component: () => import('@/views/safe/rep/index.vue') }] },
  { path: '/safe/peo', name: 'safe-peo', component: Home, children: [{ path: '/safe/peo', name: 'safe-peo', component: () => import('@/views/safe/peo/index.vue') }] },
  { path: '/safe/type', name: 'safe-type', component: Home, children: [{ path: '/safe/type', name: 'safe-type', component: () => import('@/views/safe/type/index.vue') }] },

  { path: '/msg/notice', name: 'fix-notice', component: Home, children: [{ path: '/msg/notice', name: 'fix-notice', component: () => import('@/views/msg/notice/index.vue') }] },
  { path: '/wfl/conf', name: 'wfl-conf', component: Home, children: [{ path: '/wfl/conf', name: 'wfl-conf', component: () => import('@/views/wfl/conf/index.vue') }] },
  { path: '/wfl/index', name: 'wfl-index', component: Home, children: [{ path: '/wfl/index', name: 'wfl-index', component: () => import('@/views/wfl/index.vue') }] },
  { path: '/wfl/act', name: 'wfl-act', component: Home, children: [{ path: '/wfl/act', name: 'wfl-act', component: () => import('@/views/wfl/act/index.vue') }] },
  { path: '/wfl/act/pro', name: 'wfl-act-pro', component: Home, children: [{ path: '/wfl/act/pro', name: 'wfl-act-pro', component: () => import('@/views/wfl/act/pro.vue') }] },
  { path: '/wfl/act/lst', name: 'wfl-act-lst', component: Home, children: [{ path: '/wfl/act/lst', name: 'wfl-act-lst', component: () => import('@/views/wfl/act/lst.vue') }] },

  { path: '/rent/index', name: 'rent', component: Home, children: [{ path: '/rent/index', name: 'rent', component: () => import('@/views/rent/index.vue') }] },

  { path: '/utl/rent', name: 'utl-rent', component: Home, children: [{ path: '/utl/rent', name: 'utl-rent', component: () => import('@/views/utl/rent/index.vue') }] },

  { path: '/meet/info', name: 'meet-info', component: Home, children: [{ path: '/meet/info', name: 'meet-info', component: () => import('@/views/meet/info/index.vue') }] },
  { path: '/meet/conf', name: 'meet-conf', component: Home, children: [{ path: '/meet/conf', name: 'meet-conf', component: () => import('@/views/meet/conf/index.vue') }] },

  { path: '/utl/test', name: 'utl-test', component: Home, children: [{ path: '/utl/test', name: 'utl-test', component: () => import('@/views/utl/test.vue') }] },

  { path: '/func/list', name: 'func-list', component: Home, children: [{ path: '/func/list', name: 'func-list', component: () => import('@/views/func/list') }] },
  { path: '/func/info', name: 'func-info', component: Home, children: [{ path: '/func/info', name: 'func-info', component: () => import('@/views/func/info') }] },
  { path: '/func/rep', name: 'func-rep', component: Home, children: [{ path: '/func/rep', name: 'func-rep', component: () => import('@/views/func/rep') }] },

  { path: '/perform/mould', name: 'perform-mould', component: Home, children: [{ path: '/perform/mould', name: 'perform-mould', component: () => import('@/views/performance/mould/index.vue') }] },
  { path: '/perform/create', name: 'perform-create', component: Home, children: [{ path: '/perform/create', name: 'perform-create', component: () => import('@/views/performance/mould/create.vue') }] },
  { path: '/perform/edit', name: 'perform-edit', component: Home, children: [{ path: '/perform/edit', name: 'perform-edit', component: () => import('@/views/performance/mould/edit.vue') }] },
  { path: '/perform/back', name: 'perform-back', component: Home, children: [{ path: '/perform/back', name: 'perform-back', component: () => import('@/views/performance/mould/back.vue') }] },

  { path: '/perform/exa', name: 'perform-exa', component: Home, children: [{ path: '/perform/exa', name: 'perform-exa', component: () => import('@/views/performance/perfor/index.vue') }] },
  { path: '/perform/info', name: 'perform-info', component: Home, children: [{ path: '/perform/info', name: 'perform-info', component: () => import('@/views/performance/perfor/info.vue') }] },
  { path: '/perform/change', name: 'perform-change', component: Home, children: [{ path: '/perform/change', name: 'perform-change', component: () => import('@/views/performance/perfor/change.vue') }] },
  { path: '/perform/exaCre', name: 'perform-exaCre', component: Home, children: [{ path: '/perform/exaCre', name: 'perform-exaCre', component: () => import('@/views/performance/perfor/exaCre.vue') }] },
  { path: '/perform/exaCre2', name: 'perform-exaCre2', component: Home, children: [{ path: '/perform/exaCre2', name: 'perform-exaCre2', component: () => import('@/views/performance/perfor/exaCre2.vue') }] },
  { path: '/perform/exaEdit', name: 'perform-exaEdit', component: Home, children: [{ path: '/perform/exaEdit', name: 'perform-exaEdit', component: () => import('@/views/performance/perfor/exaEdit.vue') }] },

  { path: '/visitor/index', name: 'visitor-index', component: Home, children: [{ path: '/visitor/index', name: 'visitor-index', component: () => import('@/views/visitor/index.vue') }] },

  { path: '/ins/record', name: 'ins-record', component: Home, children: [{ path: '/ins/record', name: 'ins-record', component: () => import('@/views/ins/record/index.vue') }] },
  { path: '/ins/type', name: 'ins-type', component: Home, children: [{ path: '/ins/type', name: 'ins-type', component: () => import('@/views/ins/type/index.vue') }] },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!to.meta.notLogin) {
    let token = localStorage.getItem('loginToken') || '';
    let timestamp = (new Date()).getTime();
    let expires = localStorage.getItem('tokenExpires') || 0;
    // console.log(token,expires,timestamp)
    if (token && expires > timestamp) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})
export default router
