import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
import router from "./router/index2";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import "babel-polyfill";
import Print from "vue-print-nb";
Vue.use(Print);

Vue.config.productionTip = false;
Vue.filter("datetime", function (val, f) {
  if (!val) return "";
  return moment(val).format(f);
});
Vue.prototype.$moment = moment;
Vue.use(ElementUI, { zIndex: 1000 });

import whalePlugin from "./util/whale";
Vue.use(whalePlugin);

import Swal from "sweetalert2";
Vue.prototype.$swal = Swal;

import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// Vue.http.interceptors.push((request,next)=>{
//   //request.credentials = true; // 接口每次请求会跨域携带cookie
//   //request.method= 'POST'; // 请求方式（get,post）
//   request.headers.set('token1','111') // 请求headers携带参数
//
//   next(function(response){
//     return response;
//
//   });
// })
// axios.defaults.withCredentials = true
// // 设置请求拦截器
// axios.interceptors.request.use(config => {
//   if (localStorage.getItem('loginToken')) {
//     config.headers.Authorization = localStorage.getItem('loginToken')
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
