<template>
  <ul class="navigation">
    <li class="divider" style="margin-top:8px;background-color: transparent">
      <router-link to="/">
        <router-link to="/default" style="padding: 0px">
          <h4><i class="ti-home"></i> 首页</h4>
        </router-link>
      </router-link>
      <hr />
    </li>
    <template v-for="it in ls">
      <li class="menu-dropdown" :key="it.ID" v-if="it.IS_SHOW == 0">
        <a :href="it.URL" class="dropdown_head">
          <i :class="it.CSS"></i>
          <span>{{ it.MENU_NAME }}</span>
          <span class="ti-angle-up"></span>
        </a>
        <ul class="sub_menu" v-if="!it.LEAF">
          <li v-for="c in it.CHILDREN" :key="c.ID">
            <router-link :to="c.URL">
              <i class="ti-angle-double-right"></i> {{ c.MENU_NAME }}
            </router-link>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
  import $ from 'jquery'
  export default {
    name: "wnav",
    props: {},
    data() {
      return {
        ls: [],
      }
    },
    created() {
      this.getlist();
    },
    methods: {
      getlist() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/ACL/MenuApi/GetTree",
          completed: function (its) {
            self.ls = its.DATA;
            setTimeout(self.jrendor, 10);
          }
        })

        // this.whale.remote.getResult({
        //   url:"/api/Captcha/GetPictureCaptcha",
        //   completed:function (its){
        //     console.log(its)
        //   }
        // })
      },
      jrendor() { //rare 原代码，jquery实现
        let animationSpeed = 300,
          subMenuSelector = '.sub_menu';
        $(".menu-dropdown:not('.active')").find(subMenuSelector).slideUp("fast");
        $(".menu-dropdown.active").find("li:not('.active')").find(".sub-submenu").slideUp("fast");
        $('.navigation li a').on('click', function (e) {
          let $this = $(this);
          let checkElement = $this.next();
          if (checkElement.is(subMenuSelector) && checkElement.is(':visible')) {
            checkElement.slideUp(animationSpeed, function () {
              checkElement.removeClass('active');
            });
            checkElement.parent("li").removeClass("active");
          } else if ((checkElement.is(subMenuSelector)) && (!checkElement.is(':visible'))) {
            let parent = $this.parents('ul').first();
            let ul = parent.find('ul:visible').slideUp(animationSpeed);
            ul.removeClass('active');
            let parent_li = $this.parent("li");
            checkElement.slideDown(animationSpeed).addClass('active');
            parent.find('li.active').removeClass('active');
            parent_li.addClass('active');
          }
          if (checkElement.is(subMenuSelector)) {
            e.preventDefault();
          }
        });

        //还原主菜单状态
        let rt = window.location.hash;
        rt = rt.substr(1); //去掉最前面的#号
        let lis = $("ul.navigation > li");
        $.each(lis, function (i, o) {
          let href = $(o).children("a").attr("href");
          if (rt.startsWith(href)) {
            let _this = $(o).children("a");
            let checkElement = _this.next();
            let parent = _this.parents('ul').first();
            let ul = parent.find('ul:visible').slideUp(300);
            ul.removeClass('active');
            let parent_li = _this.parent("li");
            checkElement.slideDown(300).addClass('active');
            parent.find('li.active').removeClass('active');
            parent_li.addClass('active');
          }
        });
      }
    }
  }
</script>
<style scoped></style>